@use 'variaveis';
@use 'header';
@use 'descricao';
@use 'projetos';
@use 'sobre';
@use 'contato';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    list-style: none;
}

body {
    background-image: url(/src/images/frame.png);
    background-size: contain;
    background-repeat: no-repeat;

    background-color: variaveis.$corFundo;
    color: variaveis.$corTexto;
    margin-bottom: 20px;

    @media (max-width: 1024px) {
        background-image: url(/src/images/frame_mobile.png);
        background-size: contain;
        background-repeat: no-repeat;
    }

    .container {
        max-width: 1280px;
        width: 100%;
        margin: 0 auto;
    }
}