// CORES
$corFundo: #181818;
$corTexto: #fafbff;
$corBotao: #4B67FC;
$corBotaoHover: #4b66fc6e;
$corTitulo: rgba(250, 251, 255, 0.65);
$corTags: #F3F4F6;
$corBordas: rgba(255, 255, 255, 0.30);
$gradiente: linear-gradient(135deg, rgba(43,58,136,1) 0%, rgba(24,24,24,1) 50%, rgba(75,103,252,1) 100%);

// FONTES
$inter: 'Inter', sans-serif;
$andada: 'Andada Pro', serif;