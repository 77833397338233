@use 'variaveis';

.projetos {
    .container {
        max-width: 1280px;
        width: 100%;

        @media (max-width: 1024px) {
            width: 90%;
        }
    }

    &__titulo {

        h2 {
            width: 100%;
            padding-bottom: 20px;
            border-bottom: 1px solid #fff;
            font-size: 40px;
            font-weight: normal;
            line-height: 56px;

            @media (max-width: 1024px) {
                font-size: 24px;
                line-height: 32px;
            }
        }
    }

    &__projeto {
        margin-top: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 1024px) {
            display: block;
        }

        &__data {
            font-family: variaveis.$inter;
            font-size: 12px;
            line-height: 60px;

            @media (max-width: 1024px) {
                line-height: 24px;
            }
        }

        &__descricao {
            margin-top: 40px;

            &__texto {
                h3 {
                    font-size: 42px;
                    font-weight: 400;
                    line-height: 60.802px;

                    @media (max-width: 1024px) {
                        font-size: 24px;
                        line-height: 32px;
                    }
                }

                p {
                    margin-top: 14px;
                    font-family: variaveis.$inter;
                    line-height: 28px;

                    
                    @media (max-width: 1024px) {
                        font-size: 12px;
                        line-height: 24px;
                    }
                }

                &__tags {
                    display: flex;
                    margin-top: 40px;

                    @media (max-width: 1024px) {
                        align-items: flex-start;
                        align-content: flex-start;
                        gap: 12px;
                        align-self: stretch;
                        flex-wrap: wrap;
                    }

                    p {
                        color: variaveis.$corTags;
                        font-family: variaveis.$inter;
                        font-size: 14px;
                        line-height: 14px;
                        border-radius: 5px;
                        border: 1px solid variaveis.$corBordas;
                        margin-right: 12px;
                        padding: 16px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        @media (max-width: 1024px) {
                            font-size: 12px;
                            line-height: 12px;
                        }
                    }
                }
            }
        }
    }

    img {
        @media (max-width: 1024px) {
            width: 100%;
            margin-top: 40px;
        }
    }
}