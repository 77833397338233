@use 'variaveis';

.contato {
    .container {
        max-width: 1280px;
        width: 100%;
        margin-top: 100px;

        @media (max-width: 1024px) {
            width: 90%;
            margin-top: 56px;
        }
    }

    &__titulo {
        h2 {
            width: 100%;
            padding-bottom: 20px;
            border-bottom: 1px solid #fff;
            font-size: 40px;
            font-weight: normal;
            line-height: 56px;

            @media (max-width: 1024px) {
                font-size: 24px;
                line-height: 32px;
            }
        }
    }

    &__form {
        margin-top: 50px;
        font-family: variaveis.$inter;

        &__item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 24px;

            label {
                line-height: 24px;
                margin-bottom: 12px;

                @media (max-width: 1024px) {
                    font-size: 12px;
                    line-height: 16px;
                }
            }

            input {
                width: 600px;
                height: 56px;
                padding: 10px;
                border-radius: 4px;
                border: 1px solid variaveis.$corBordas;
                opacity: 0.8;
                background-color: variaveis.$corFundo;
                color: variaveis.$corTexto;
                font-size: 16px;

                @media (max-width: 1024px) {
                    width: 100%;
                    height: 40px;
                    font-size: 12px;
                    line-height: 16px;
                }
            }

            textarea {
                width: 600px;
                height: 180px;
                padding: 10px;
                border-radius: 4px;
                border: 1px solid variaveis.$corBordas;
                opacity: 0.8;
                background-color: variaveis.$corFundo;
                color: variaveis.$corTexto;
                font-size: 16px;
                resize: none;

                @media (max-width: 1024px) {
                    width: 100%;
                    height: 144px;
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }
        button {
            width: 176px;
            height: 64px;
            background-color: variaveis.$corBotao;
            color: variaveis.$corTexto;
            font-family: variaveis.$inter;
            font-size: 24px;
            line-height: 32px;
            letter-spacing: 0.24px;
            border-radius: 5px;
            border: 1px solid variaveis.$corBordas;
            cursor: pointer;

            &:hover {
                background-color: variaveis.$corBotaoHover;
            }

            @media (max-width: 1024px) {
                width: 136px;
                height: 52px;
                margin-top: 40px;
                margin-left: 8px;
                font-size: 18px;
                line-height: 18px;
                letter-spacing: 0.18px;
            }
        }
    }
}