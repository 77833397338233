.header {
  font-family: Inter, sans-serif;
}

.header .container {
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

@media (width <= 1024px) {
  .header .container {
    justify-content: space-between;
    width: 90%;
  }

  .header__desktop {
    display: none;
  }
}

.header__desktop__menu {
  display: flex;
}

.header__desktop__menu__item {
  margin-left: 48px;
}

.header__desktop__menu__item__link {
  color: #fafbff;
  text-decoration: none;
}

.header__mobile {
  display: none;
}

@media (width <= 1024px) {
  .header__mobile {
    display: flex;
  }

  .header__mobile__top {
    z-index: 100;
    background-color: #181818;
    border: 1px solid #ffffff4d;
    border-radius: 10px;
    padding: 0 8px;
    position: fixed;
    top: 1rem;
    left: 1rem;
  }

  .header__mobile__top__menu {
    justify-content: flex-start;
    gap: 32px;
    padding: 8px;
    display: flex;
  }

  .header__mobile__top__menu__item__link {
    text-decoration: none;
  }

  .header__mobile__top__menu__item__link .bi {
    color: #fafbff;
    background-color: #181818;
  }

  .header__mobile__top__menu__hamburger {
    z-index: 100;
    cursor: pointer;
    color: #fafbff;
    background-color: #181818;
    border: 1px solid #ffffff4d;
    border-radius: 10px;
    margin-top: -16px;
    padding: 8px;
    position: fixed;
    top: 2rem;
    right: 1rem;
  }

  .header__mobile .closeIcon {
    display: none;
  }

  .header__mobile__menu {
    z-index: 99;
    background-color: #181818;
    padding-top: 8rem;
    list-style: none;
    transition: transform .2s;
    position: fixed;
    inset: 0;
    transform: translateY(-100%);
  }

  .header__mobile__menu__item {
    background-color: #181818;
    margin-left: 16px;
  }

  .header__mobile__menu__item__link {
    color: #fafbff;
    letter-spacing: .48px;
    background-color: #181818;
    font-size: 24px;
    line-height: 40px;
    text-decoration: none;
  }

  .header__mobile .showMenu {
    transform: translateY(0);
  }
}

.descricao .container {
  justify-content: center;
  width: 100%;
  max-width: 1280px;
  display: flex;
}

@media (width <= 1024px) {
  .descricao .container {
    width: 90%;
    display: block;
  }
}

.descricao__bloco {
  justify-content: space-between;
  align-items: center;
  padding-top: 152px;
  display: flex;
}

@media (width <= 1024px) {
  .descricao__bloco {
    width: 90%;
    margin: 0 8px;
  }
}

.descricao__bloco__texto {
  justify-content: flex-start;
  width: 100%;
  max-width: 936px;
}

.descricao__bloco__texto__titulo {
  color: #fafbffa6;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (width <= 1024px) {
  .descricao__bloco__texto__titulo {
    letter-spacing: .8px;
    font-size: 16px;
  }
}

.descricao__bloco__texto__descricao {
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
}

@media (width <= 1024px) {
  .descricao__bloco__texto__descricao {
    font-size: 24px;
    line-height: 40px;
  }

  .descricao__bloco__contato {
    display: none;
  }
}

.descricao__bloco__contato__nav {
  z-index: 100;
  background-color: #fafbff;
  border: 1px solid #ffffff4d;
  border-radius: 10px;
  padding: 8px;
  position: fixed;
}

.descricao__bloco__contato__nav__menu {
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  display: flex;
}

.descricao__bloco__contato__nav__menu__item__link {
  text-decoration: none;
}

.descricao__bloco__contato__nav__menu__item__link .bi {
  color: #181818;
}

.descricao button {
  color: #fafbff;
  letter-spacing: .24px;
  cursor: pointer;
  background-color: #4b67fc;
  border: 1px solid #ffffff4d;
  border-radius: 5px;
  width: 176px;
  height: 64px;
  margin-top: 80px;
  font-family: Inter, sans-serif;
  font-size: 24px;
  line-height: 32px;
}

.descricao button:hover {
  background-color: #4b66fc6e;
}

@media (width <= 1024px) {
  .descricao button {
    letter-spacing: .18px;
    width: 136px;
    height: 52px;
    margin-top: 40px;
    margin-left: 8px;
    font-size: 18px;
    line-height: 18px;
  }
}

.descricao .seta {
  text-align: center;
  margin-top: 96px;
}

@media (width <= 1024px) {
  .descricao .seta {
    margin-top: 48px;
  }
}

.projetos .container {
  width: 100%;
  max-width: 1280px;
}

@media (width <= 1024px) {
  .projetos .container {
    width: 90%;
  }
}

.projetos__titulo h2 {
  border-bottom: 1px solid #fff;
  width: 100%;
  padding-bottom: 20px;
  font-size: 40px;
  font-weight: normal;
  line-height: 56px;
}

@media (width <= 1024px) {
  .projetos__titulo h2 {
    font-size: 24px;
    line-height: 32px;
  }
}

.projetos__projeto {
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
  display: flex;
}

@media (width <= 1024px) {
  .projetos__projeto {
    display: block;
  }
}

.projetos__projeto__data {
  font-family: Inter, sans-serif;
  font-size: 12px;
  line-height: 60px;
}

@media (width <= 1024px) {
  .projetos__projeto__data {
    line-height: 24px;
  }
}

.projetos__projeto__descricao {
  margin-top: 40px;
}

.projetos__projeto__descricao__texto h3 {
  font-size: 42px;
  font-weight: 400;
  line-height: 60.802px;
}

@media (width <= 1024px) {
  .projetos__projeto__descricao__texto h3 {
    font-size: 24px;
    line-height: 32px;
  }
}

.projetos__projeto__descricao__texto p {
  margin-top: 14px;
  font-family: Inter, sans-serif;
  line-height: 28px;
}

@media (width <= 1024px) {
  .projetos__projeto__descricao__texto p {
    font-size: 12px;
    line-height: 24px;
  }
}

.projetos__projeto__descricao__texto__tags {
  margin-top: 40px;
  display: flex;
}

@media (width <= 1024px) {
  .projetos__projeto__descricao__texto__tags {
    flex-wrap: wrap;
    align-content: flex-start;
    align-self: stretch;
    align-items: flex-start;
    gap: 12px;
  }
}

.projetos__projeto__descricao__texto__tags p {
  color: #f3f4f6;
  border: 1px solid #ffffff4d;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  padding: 16px;
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 14px;
  display: flex;
}

@media (width <= 1024px) {
  .projetos__projeto__descricao__texto__tags p {
    font-size: 12px;
    line-height: 12px;
  }

  .projetos img {
    width: 100%;
    margin-top: 40px;
  }
}

.sobre .container {
  width: 100%;
  max-width: 1280px;
  margin-top: 100px;
}

@media (width <= 1024px) {
  .sobre .container {
    width: 90%;
    margin-top: 56px;
  }
}

.sobre__titulo h2 {
  border-bottom: 1px solid #fff;
  width: 100%;
  padding-bottom: 20px;
  font-size: 40px;
  font-weight: normal;
  line-height: 56px;
}

@media (width <= 1024px) {
  .sobre__titulo h2 {
    font-size: 24px;
    line-height: 32px;
  }
}

.sobre__conteudo {
  justify-content: space-between;
  align-items: flex-start;
  height: 432px;
  margin-top: 52px;
  display: flex;
}

@media (width <= 1024px) {
  .sobre__conteudo {
    margin-top: 40px;
    display: block;
  }
}

.sobre__conteudo__texto {
  width: 640px;
  font-family: Inter, sans-serif;
}

@media (width <= 1024px) {
  .sobre__conteudo__texto {
    width: 100%;
    margin-top: 40px;
  }
}

.sobre__conteudo__texto p {
  font-size: 16px;
}

@media (width <= 1024px) {
  .sobre__conteudo__texto p {
    font-size: 12px;
    line-height: 24px;
  }
}

.sobre__conteudo__foto {
  border-radius: 8px;
  height: 100%;
  margin-right: 32px;
}

@media (width <= 1024px) {
  .sobre__conteudo__foto {
    height: 264px;
    margin-top: 40px;
  }
}

.sobre__skills {
  margin-top: 52px;
}

@media (width <= 1024px) {
  .sobre__skills {
    margin-top: 360px;
    padding-top: 160px;
  }
}

.sobre__skills__titulo {
  text-transform: uppercase;
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-weight: normal;
  line-height: 56px;
}

.sobre__skills__tags {
  margin-top: 40px;
  display: flex;
}

@media (width <= 1024px) {
  .sobre__skills__tags {
    flex-wrap: wrap;
    align-content: flex-start;
    align-self: stretch;
    align-items: flex-start;
    gap: 12px;
  }
}

.sobre__skills__tags p {
  color: #f3f4f6;
  border: 1px solid #ffffff4d;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  padding: 16px;
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 14px;
  display: flex;
}

@media (width <= 1024px) {
  .sobre__skills__tags p {
    font-size: 12px;
    line-height: 12px;
  }
}

.contato .container {
  width: 100%;
  max-width: 1280px;
  margin-top: 100px;
}

@media (width <= 1024px) {
  .contato .container {
    width: 90%;
    margin-top: 56px;
  }
}

.contato__titulo h2 {
  border-bottom: 1px solid #fff;
  width: 100%;
  padding-bottom: 20px;
  font-size: 40px;
  font-weight: normal;
  line-height: 56px;
}

@media (width <= 1024px) {
  .contato__titulo h2 {
    font-size: 24px;
    line-height: 32px;
  }
}

.contato__form {
  margin-top: 50px;
  font-family: Inter, sans-serif;
}

.contato__form__item {
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;
  display: flex;
}

.contato__form__item label {
  margin-bottom: 12px;
  line-height: 24px;
}

@media (width <= 1024px) {
  .contato__form__item label {
    font-size: 12px;
    line-height: 16px;
  }
}

.contato__form__item input {
  opacity: .8;
  color: #fafbff;
  background-color: #181818;
  border: 1px solid #ffffff4d;
  border-radius: 4px;
  width: 600px;
  height: 56px;
  padding: 10px;
  font-size: 16px;
}

@media (width <= 1024px) {
  .contato__form__item input {
    width: 100%;
    height: 40px;
    font-size: 12px;
    line-height: 16px;
  }
}

.contato__form__item textarea {
  opacity: .8;
  color: #fafbff;
  resize: none;
  background-color: #181818;
  border: 1px solid #ffffff4d;
  border-radius: 4px;
  width: 600px;
  height: 180px;
  padding: 10px;
  font-size: 16px;
}

@media (width <= 1024px) {
  .contato__form__item textarea {
    width: 100%;
    height: 144px;
    font-size: 12px;
    line-height: 16px;
  }
}

.contato__form button {
  color: #fafbff;
  letter-spacing: .24px;
  cursor: pointer;
  background-color: #4b67fc;
  border: 1px solid #ffffff4d;
  border-radius: 5px;
  width: 176px;
  height: 64px;
  font-family: Inter, sans-serif;
  font-size: 24px;
  line-height: 32px;
}

.contato__form button:hover {
  background-color: #4b66fc6e;
}

@media (width <= 1024px) {
  .contato__form button {
    letter-spacing: .18px;
    width: 136px;
    height: 52px;
    margin-top: 40px;
    margin-left: 8px;
    font-size: 18px;
    line-height: 18px;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}

body {
  color: #fafbff;
  background-color: #181818;
  background-image: url("frame.7f21a7fa.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 20px;
}

@media (width <= 1024px) {
  body {
    background-image: url("frame_mobile.228d8d79.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
}

body .container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

/*# sourceMappingURL=index.17b0dc8e.css.map */
