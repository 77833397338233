@use 'variaveis';

.sobre {
    .container {
        max-width: 1280px;
        width: 100%;
        margin-top: 100px;

        @media (max-width: 1024px) {
            width: 90%;
            margin-top: 56px;
        }
    }

    &__titulo {
        h2 {
            width: 100%;
            padding-bottom: 20px;
            border-bottom: 1px solid #fff;
            font-size: 40px;
            font-weight: normal;
            line-height: 56px;

            @media (max-width: 1024px) {
                font-size: 24px;
                line-height: 32px;
            }
        }
    }

    &__conteudo {
        margin-top: 52px;
        height: 432px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media (max-width: 1024px) {
            display: block;
            margin-top: 40px;
        }

        &__texto {
            width: 640px;
            font-family: variaveis.$inter;

            @media (max-width: 1024px) {
                margin-top: 40px;
                width: 100%;
            }

            p {
                font-size: 16px;

                @media (max-width: 1024px) {
                    font-size: 12px;
                    line-height: 24px;
                }
            }
        }

        &__foto {
            height: 100%;
            border-radius: 8px;
            margin-right: 32px;

            @media (max-width: 1024px) {
                margin-top: 40px;
                height: 264px;
            }
        }
    }

    &__skills {
        margin-top: 52px;

        @media (max-width: 1024px) {
            margin-top: 360px;
            padding-top: 160px;
        }

        &__titulo {
            font-family: variaveis.$inter;
            font-size: 24px;
            text-transform: uppercase;
            font-weight: normal;
            line-height: 56px;
        }

        &__tags {
            display: flex;
            margin-top: 40px;

            @media (max-width: 1024px) {
                align-items: flex-start;
                align-content: flex-start;
                gap: 12px;
                align-self: stretch;
                flex-wrap: wrap;
            }

            p {
                color: variaveis.$corTags;
                font-family: variaveis.$inter;
                font-size: 14px;
                line-height: 14px;
                border-radius: 5px;
                border: 1px solid variaveis.$corBordas;
                margin-right: 12px;
                padding: 16px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                @media (max-width: 1024px) {
                    font-size: 12px;
                    line-height: 12px;
                }
            }
        }
    }
}